









import { Component, Prop, Vue } from "vue-property-decorator";

import { dashboardCreationDto } from "@/shared/dtos/dashboardCreationDto";

@Component({
  components: {
    BasicGauge: () => import("@/components/Charts/BasicGauges/BasicGauge.vue"),
  },
})
export default class GaugesChartNormalAyuntamiento extends Vue {
  @Prop() chart_prop!: dashboardCreationDto[];
}
